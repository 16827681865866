:root {
  --bs-primary-rgb: 251,143,20;
  --header-top-height: 62px;
  --sidebar-left-width: 250px;
  --formgenerator-footer-height: 70px;
  --formgenerator-upload-image-width: 50%;
  --formgenerator-upload-image-height: 150px;
}
@media (max-width: 767px) {
  :root {
    --formgenerator-upload-image-width: 100%;
  }
}

body {
  background: #ffffff;
  overflow-x: hidden;
}

header {
  background: #ffffff;
  z-index: 10;
}

.user-icon {
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
}

.rbt-close-content { 
  display: none;
}

.main-content {
  height: calc(100vh - var(--header-top-height));
}

.formgenerator-content-wfooter {
  padding-bottom: var(--formgenerator-footer-height);
}

.offcanvas-body .formgenerator-footer {
  width: calc(var(--bs-offcanvas-width) - 1px) !important;
  right: 0 !important;
  left: auto !important;
}

.main-content {
  transition: all 0.3s;
}
.ps-sidebar-root {
  left: calc(-1 * var(--sidebar-left-width));
}
.ps-sidebar-root.ps-toggled {
  left: 0px;
}
.ps-sidebar-root ~ .main-content {
  margin-left: 0px;
}
.ps-sidebar-root ~ .formgenerator-footer {
  left: 0px;
}
.ps-sidebar-backdrop {
  display: none;
}
.sidebar-hamburger {
  left: 20px;
  transition: all 0.3s;
}
.sidebar-hamburger.toggled {
  left: calc(var(--sidebar-left-width) + 20px);
}
@media (max-width: 799px) {
  .ps-sidebar-root {
    top: var(--header-top-height) !important;
    z-index: 9999;
    background: white;
  }
  .sidebar-hamburger.toggled {
    left: 20px !important;
  }
  .formgenerator-footer {
    left: 0 !important;
    width: 100% !important;
  }
}
@media (min-width: 800px) {
  .ps-sidebar-root.ps-toggled ~ .main-content {
    margin-left: var(--sidebar-left-width);
  }
}

.rs-uploader-picture { width: 100%; }
.rs-uploader-picture .rs-uploader-file-items { display: flex; }
.rs-uploader-picture .rs-uploader-trigger-customize { width: 100%; }
.rs-uploader-picture .rs-uploader-file-item-picture { width: var(--formgenerator-upload-image-width); height: var(--formgenerator-upload-image-height); }
.rs-uploader-picture .rs-uploader-file-item-preview { height: 100%; }
.rs-uploader-picture .rs-uploader-file-item-icon-loading { width: 100%;  height: var(--formgenerator-upload-image-height); }
.rs-uploader-picture .rs-uploader-file-item { width: var(--formgenerator-upload-image-width); height: var(--formgenerator-upload-image-height); margin: 10px 0px 0px; }
.rs-uploader-picture .rs-uploader-file-item-status { width: var(--formgenerator-upload-image-width); height: var(--formgenerator-upload-image-height); }
.rs-uploader-picture .rs-uploader-file-item-preview>img { object-fit: contain; }

.rs-table-row-custom .rs-table-cell-content { padding: 10px 10px; }

.avatar-dropzone .rs-avatar:hover::after {
  content: '+';
  position: absolute;
  top: -2px;
  left: 0;
  color: white;
  font-size: 30px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Resizer */
/* .app-container {
  min-height: 100vh;
  padding: 1em 1em;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.app-sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 150px;
  max-width: 300px;
  display: flex;
  border-right: #e9e9e9 1px solid;
  flex-direction: row;
  background: #ffffff;
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
  z-index: 2;
}

.app-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  height: 100vh;
  max-height: 100%;
  background: #ffffff;
  box-shadow: 8px 2px 32px -2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
}

.app-sidebar .app-sidebar-content {
  flex: 1;
}

.app-sidebar .app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
}

.app-sidebar .app-sidebar-resizer:hover {
  width: 3px;
  background: #c1c3c5b4;
} */
